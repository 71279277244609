/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement("iframe", {
    width: "560",
    height: "315",
    src: "https://www.youtube.com/embed/WwIS3J9dNjE",
    frameBorder: "0",
    allowFullScreen: true
  }), "\n", React.createElement(_components.p, null, "Experimenting with the Rapsberry Pi’s camera module."), "\n", React.createElement(_components.p, null, "For this recording I just taped the camera to the window frame of my room and it slipped in the middle of the night.\nOnce I woke up I corrected it so that’s why it falls over around 28 second in."), "\n", React.createElement(_components.p, null, "Some resources here:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/documentation/usage/camera/README.md"
  }, "https://www.raspberrypi.org/documentation/usage/camera/README.md")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/products/camera-module/"
  }, "https://www.raspberrypi.org/products/camera-module/")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/documentation/usage/camera/raspicam/raspistill.md"
  }, "https://www.raspberrypi.org/documentation/usage/camera/raspicam/raspistill.md")), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "https://www.raspberrypi.org/documentation/usage/camera/raspicam/timelapse.md"
  }, "https://www.raspberrypi.org/documentation/usage/camera/raspicam/timelapse.md")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
